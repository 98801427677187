import { i18n } from '@lingui/core';
import { Item } from '../data/recommendationDataTypes';

export function getTitle(
    adId: number,
    isPersonal: boolean,
    numberOfAds: number,
    overrideTitle: string,
): string {
    if (numberOfAds > 0) {
        if (overrideTitle) {
            return overrideTitle;
        }
        if (adId) {
            return i18n.t({
                id: 'ads.title.mlt',
                message: 'More like this',
            });
        } else if (isPersonal) {
            return i18n.t({
                id: 'ads.title.personal',
                message: 'Personalized recommendations',
            });
        } else {
            return i18n.t({
                id: 'ads.title.popular',
                message: 'Popular ads',
            });
        }
    }
    return '';
}

export function showLoadMoreButton(
    renderAllRows: boolean,
    isBottomReached: boolean,
    hasMoreFetchData: boolean,
    numberOfAds: number,
): boolean {
    return (
        numberOfAds > 0 &&
        !renderAllRows &&
        isBottomReached &&
        !hasMoreFetchData
    );
}

export function showHistoryLink(
    numberOfAds: number,
    brand: string,
    isFrontpage: boolean,
    isPersonal: boolean,
): boolean {
    return (
        numberOfAds > 0 &&
        brand.toUpperCase() === 'FINN' &&
        isFrontpage &&
        isPersonal
    );
}

export function getAdProperties(item: Item) {
    const itemIdNumber: number = Number(item.itemId);
    const shippingLabel = item.shippingOption?.label;
    const p4pLabel = item.overlay?.p4p?.label;
    const easyapplyLabel = item.overlay?.easyapply?.label;
    const isCmsArticle = item?.type?.toUpperCase() === 'CMS';
    const redirectUrl = isCmsArticle ? `${item.itemId}` : `/${item.itemId}`;

    return {
        itemIdNumber,
        shippingLabel,
        p4pLabel,
        easyapplyLabel,
        isCmsArticle,
        redirectUrl,
    };
}
