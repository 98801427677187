import { i18n } from '@lingui/core';
import { messages as messagesEn } from '../../locales/en/messages.mjs';
import { messages as messagesFi } from '../../locales/fi/messages.mjs';
import { messages as messagesNb } from '../../locales/nb/messages.mjs';
import { messages as messagesDa } from '../../locales/da/messages.mjs';

export const loadI18nStatic = () => {
    i18n.load({
        en: messagesEn,
        nb: messagesNb,
        fi: messagesFi,
        da: messagesDa,
    });
};

export const activateI18n = (lang: string) => {
    i18n.activate(lang);
};

export const loadAndActivateI18n = (lang: string) => {
    i18n.load({
        en: messagesEn,
        nb: messagesNb,
        fi: messagesFi,
        da: messagesDa,
    });

    activateI18n(lang);
};
