import pulse from '@finn-no/pulse-sdk';
import { PulseRecommendation } from '../data/recommendationDataTypes';

interface EventItem {
    type: 'Click' | 'View';
    eventId: string;
    timestamp: number;
}

export default class PulseTracking {
    position: string;
    enabled: boolean;
    trackingContext: Record<string, unknown>;

    constructor({
        position = '',
        trackingContext = null,
    }: {
        position?: string;
        trackingContext?: Record<string, unknown>;
    } = {}) {
        this.position = position;
        this.enabled = !!position;
        this.trackingContext = trackingContext;
    }

    trackItemToPulseBasedOnContext(
        itemContext: Record<string, unknown>,
        keyWord: 'Click' | 'View',
        trackingContext: Record<string, unknown>,
    ): void | undefined {
        if (!this.enabled) {
            console.error(
                `ignored ${keyWord} tracking to Pulse`,
                itemContext,
                trackingContext,
            );
            return;
        }
        if (!itemContext || !trackingContext) return;
        for (const [key, value] of Object.entries(itemContext)) {
            const eventArray = value as EventItem[];
            const event = eventArray.find((event) => event['type'] === keyWord);
            const ctxValue = trackingContext[key];
            if (event && ctxValue) {
                event[key] = ctxValue;
                pulse.trackEvent(event, {
                    disableAddClassifiedAdToItems: true,
                });
            }
        }
    }

    trackInscreenWithPulse(item: PulseRecommendation) {
        this.trackItemToPulseBasedOnContext(item, 'View', this.trackingContext);
    }

    trackClickWithPulse(item: PulseRecommendation) {
        this.trackItemToPulseBasedOnContext(
            item,
            'Click',
            this.trackingContext,
        );
    }
}
