import React, { useEffect, useRef, useState } from 'react';
import { AdvertisingSlotComponent } from '@schibsted-nmp/advertising-companion';
import PulseTracking from '../tracking/pulseTracking';
import { useTrackInscreen } from '../tracking/trackingHooks';
import { Item } from '../data/recommendationDataTypes';

type AdProps = {
    item: Item;
    tracking: PulseTracking;
    inscreenTrackingIsEnabled: boolean;
};

const AdvertisingAd: React.FC<AdProps> = ({
    item,
    tracking,
    inscreenTrackingIsEnabled,
}: AdProps) => {
    const [hasBeenTracked, setHasBeenTracked] = useState(false);
    const domRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const current = domRef.current;
        const handleAdClick = () => {
            tracking.trackClickWithPulse(item.tracking?.pulse);
        };

        if (current) {
            current.addEventListener('click', handleAdClick);
        }
        return () => {
            if (current) {
                current.removeEventListener('click', handleAdClick);
            }
        };
    }, [domRef, item, tracking]);

    useTrackInscreen({
        tracking,
        ref: domRef,
        pulseContext: item?.tracking?.pulse,
        enabled: inscreenTrackingIsEnabled,
        hasBeenTracked,
        setHasBeenTracked,
    });

    const categories = {
        main_category: item.content?.main_category,
        sub_category: item.content?.sub_category,
        prod_category: item.content?.prod_category,
    };

    return (
        <AdvertisingSlotComponent
            itemId={item.itemId}
            ref={domRef}
            categories={JSON.stringify(categories)}
            initialLayoutType={'grid'}
        />
    );
};

export default AdvertisingAd;

export function itemIsAdvertising(item: Item) {
    return item.type === 'NATIVE' || item.type === 'BANNER';
}
