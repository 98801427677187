import { useInscreenObserver } from './useInscreenObserver';
import React, { useState } from 'react';

type BottomReachedProps = {
    onBottomReachedFn: () => void;
    threshold?: number;
};
export const useBottomReached = ({ onBottomReachedFn }: BottomReachedProps) => {
    const bottomRef = React.useRef<HTMLDivElement | null>(null);
    const [isBottomReached, setIsBottomReached] = useState(false);

    const checkBottomReached = () => {
        if (bottomRef.current) {
            const reached =
                bottomRef.current.getBoundingClientRect().top <=
                window.innerHeight;
            if (reached && !isBottomReached) {
                setIsBottomReached(true);
                onBottomReachedFn();
            }
        }
    };

    useInscreenObserver(bottomRef, checkBottomReached, {
        threshold: 0,
        once: true,
    });

    return { bottomRef, isBottomReached };
};
