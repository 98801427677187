import React from 'react';
import { IconShipping16 } from '@warp-ds/icons/react/shipping-16';

type LabelsWrapperProps = {
    shippingLabel?: string | null;
    easyapplyLabel?: string | null;
    p4pLabel?: string | null;
    isCmsArticle?: boolean | null;
};

const LabelsWrapper: React.FC<LabelsWrapperProps> = ({
    shippingLabel,
    easyapplyLabel,
    p4pLabel,
    isCmsArticle,
}) => (
    <>
        {shippingLabel && (
            <span className="absolute top-0 left-0 py-4 px-8 inline-flex rounded-tl-8 rounded-br-4 text-12 s-bg-warning-subtle-hover">
                <IconShipping16 className="w-16 h-16 mr-4" />
                {shippingLabel}
            </span>
        )}

        {easyapplyLabel && (
            <span className="absolute top-0 left-0 py-4 px-8 inline-flex rounded-tl-8 rounded-br-4 text-12 s-bg-positive-subtle-hover s-text-rgb-positive-active">
                {easyapplyLabel}
            </span>
        )}

        {p4pLabel && (
            <span className="absolute top-0 left-0 py-4 px-8 inline-flex rounded-tl-8 rounded-br-4 text-12 s-bg-info-subtle-active">
                {p4pLabel}
            </span>
        )}

        {isCmsArticle && (
            <span className="absolute top-0 left-0 py-4 px-8 inline-flex rounded-tl-8 rounded-br-4 text-12 s-bg-info-subtle-active">
                Nyttige artikler
            </span>
        )}
    </>
);

export default LabelsWrapper;
