import InscreenObserver from '@finn-no/inscreen-observer';
import * as React from 'react';

type InscreenObserverOptions = {
    once?: boolean;
    proximity?: number;
    threshold?: number;
    timeout?: number;
};
const DEFAULT_OPTIONS: InscreenObserverOptions = {
    threshold: 0.75,
    timeout: 1000,
    once: true,
};
export const useInscreenObserver = (
    ref: React.MutableRefObject<Element | null>,
    callbackFn: () => void,
    options: InscreenObserverOptions = DEFAULT_OPTIONS,
) => {
    React.useEffect(() => {
        if (!ref.current) {
            /* console.error(
                'Failed to track inscreen as ref.current is not a node',
                {
                    ref,
                },
            ); */
            return;
        }
        const observer = new InscreenObserver.default(callbackFn, options);
        observer.observe(ref.current);
        return () => observer.disconnect();
    }, [ref, callbackFn, options]);
};
